import React, { useEffect, useState, useRef } from 'react';
import { getCategoriesApi } from '../../../../api/category';
import { getProductImageApi } from '../../../../api/product';

import './MenuList.scss';

import brushStroke from '../../../../assets/img/svg/brush-stroke.svg';
import Button from 'react-bootstrap/esm/Button';
import { Link } from 'react-router-dom';

export default function MenuList(props) {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    getCategoriesApi()
      .then((response) => {
        setCategories(response.categories);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const menuListRef = useRef([]);
  useEffect(() => {
    const scrollContainers = menuListRef.current;

    const startScroll = (container, direction) => {
      let scrollAmount = 0;
      let step = 1; // Adjust step size to control speed

      const scroll = () => {
        if (direction === 'right') {
          container.scrollLeft += step;
          scrollAmount += step;
          if (scrollAmount >= container.scrollWidth - container.clientWidth) {
            direction = 'left';
          }
        } else {
          container.scrollLeft -= step;
          scrollAmount -= step;
          if (scrollAmount <= 0) {
            direction = 'right';
          }
        }
        requestAnimationFrame(scroll);
      };
      scroll();
    };

    scrollContainers.forEach((container, index) => {
      const direction = index % 2 === 0 ? 'right' : 'left';

      startScroll(container, direction);
    });
  }, [categories, menuListRef]);

  return (
    <div className="menu-list" id="carta">
      <MotionTitle text="nuestra carta" />

      <div className="menu-list__categories">
        {categories &&
          categories.map((category, index) => {
            if (category.shouldBeSeenInHomepage) {
              return (
                <div className="menu-list__categories__category">
                  <div
                    className="menu-list__categories__category__title"
                    style={{
                      alignSelf: index % 2 === 0 ? 'flex-start' : 'flex-end',
                    }}
                  >
                    <img src={brushStroke} alt={category.name} />
                    <h3>{category.name}</h3>
                  </div>
                  <div
                    className="menu-list__categories__category__products"
                    ref={(el) => (menuListRef.current[index] = el)}
                  >
                    <div className="menu-list__categories__category__products__frame">
                      {category.products.map((product) => {
                        return (
                          <Product
                            name={product.name}
                            image={product.image}
                            description={product.description}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            } else {
              return <></>;
            }
          })}
        <div className="button-show-menu">
          <Link to="/carta">
            <Button
              variant="warning"
              style={{ backgroundColor: '#dba800' }}
              size="md"
            >
              Ver toda la carta
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

function MotionTitle(props) {
  const { text } = props;

  return (
    <div id="motion-title" className="menu-list__motion-title">
      <h2>{text}</h2>
    </div>
  );
}

function Product(props) {
  const { name, image, description } = props;
  const [prodImage, setProductImage] = useState(null);
  const [errorWhileGettingImage, setErrorWhileGettingImage] = useState(false);
  useEffect(() => {
    if (!errorWhileGettingImage)
      getProductImageApi(image)
        .then((response) => {
          setProductImage(response);
        })
        .catch((e) => {
          setErrorWhileGettingImage(true);
        });
  });

  return (
    <Link to="/carta">
      <div className="menu-list__categories__category__products__frame__product">
        <div className="menu-list__categories__category__products__frame__product__image">
          <img src={prodImage ? prodImage : null} alt={name} />
        </div>
        <div className="menu-list__categories__category__products__frame__product__name">
          {name}
        </div>
        {description && (
          <div className="menu-list__categories__category__products__frame__product__description">
            "{description}"
          </div>
        )}
      </div>
    </Link>
  );
}
